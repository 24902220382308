:root {
  --tint-primary: #0ab1bc;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #f5f5f5;
}

.threedots:after {
  content: "\2807";
  font-size: 30px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 24px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--tint-primary);
  border-radius: 24px;
}

@supports (scrollbar-color: grey grey) {
  * {
    scrollbar-color: var(--tint-primary) rgba(0, 0, 0, 0.3);
    scrollbar-width: thin;
  }
}

body:after{
  content: "beta";
  position: fixed;
  width: 80px;
  height: 25px;
  background: #EE8E4A;
  top: 7px;
  left: -20px;
  text-align: center;
  font-size: 13px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 27px;
  transform:rotate(-45deg);
}

div:has(>.custom-slider-tooltip){
  z-index: auto;
}